@keyframes fadeOut {
  0% {
    backdrop-filter: blur(10px);
    opacity: 1;
  }
  100% {
    backdrop-filter: blur(0px);
    opacity: 0;
  }
}

.fullscreen-animation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0000008e;
  backdrop-filter: blur(10px);
  animation: fadeOut 4s forwards; /* Анимация исчезновения */
  z-index: 1200; /* Убедитесь, что элемент над сценой */
}

.rocket {
  position: fixed;
  bottom: 0; /* Начинаем с нижней части экрана */
  left: 0; /* Начинаем с левой части экрана */
  width: 200px; /* Установите ширину ракеты */
  aspect-ratio: 1;
  animation: rocketAnimation 2s ease-in-out forwards; /* Анимация ракеты */
}

@keyframes rocketAnimation {
  0% {
    transform: translate(0, 0) scale(0.8) rotate(25deg); /* Начальное положение и размер ракеты */
  }
  50% {
    transform: translate(50vw, -50vh) scale(1.2) rotate(20deg); /* Увеличиваем размер и перемещаем к центру */
  }
  100% {
    transform: translate(100vw, -100vh) scale(1); /* Завершение анимации в верхнем правом углу */
  }
}
