

.header {
    display: flex;
    justify-content: space-between; /* Оставляем элементы по краям */
    align-items: center; /* Выравнивание по вертикали */
    background-color: var(--primary-color);
    border-bottom: 2px solid var(--border-color);
    box-shadow: 0 6px 16px rgba(40, 40, 40, 0.1);
    padding: 10px 50px;
    width: 100vw;
    height: 100px;
    position: relative; /* Позиционирование для абсолютного центрирования */
    z-index: 110;
    transition: all 0.5s ease-in;
}

.header.scrolled {
    position: fixed;
    max-height: 44px;
    background-color: transparent;
    border-bottom: 2px solid transparent;
    backdrop-filter: blur(10px) brightness(50%);
}


.header a img {
    background-color: rgba(200, 200, 200, 0.0);
    border-radius: 10px;
    width: 40px;
    height: auto;
    transition: all 300ms ease;
    &:hover {
        background-color: rgba(200, 200, 200, 0.3);
    }
}

.header a {
    margin: 0 100px 0 100px;
}

.header nav {
    position: absolute; /* Абсолютное позиционирование навигации */
    left: 50%; /* Сдвигаем навигацию к середине */
    transform: translateX(-50%); /* Центрируем относительно оси X */
    display: inline-flex; /* Ссылки в одну строку */
    gap: 20px; /* Расстояние между ссылками */
}

.header nav a {
    text-decoration: none;
    padding: 4px 6px;
    margin: 0px;
    border-radius: 10px;
    color: #dedede;
    background-color: rgba(233, 177, 177, 0.1);
    border: 1px solid transparent;
    transform: scale(1.0);
    transition: all 200ms ease-in;
    justify-items: center;

    &:hover {
        border-radius: 20px;
        transform: scale(1.05);
        background-color: rgba(200, 200, 200, 0.4);
        border: 1px solid rgba(200, 200, 200, 0.6);
    }
}