@import url(../../index.css);

details {
    user-select: none;
    color: var(--player-color);
    border-radius: 10px;
    border: 1px solid transparent;
    padding: 16px;
    margin-bottom: 10px;
    transition: all 300ms ease-in-out;
}
details[open] {
    border-radius: 20px;
    background: #7575753b;
    border: 1px solid #e2e2e23b;
}
details summary {
    cursor: pointer;
}