@import url(GoogleFonts.css);

:root {
    --primary-color: rgba(0, 0, 0, 0.8);
    --player-color: #e6e6e6;
    --border-color: rgba(0, 0, 0, 0.5);
    --all-dark-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Inter';
    margin: 0;
    padding: 0;
    background-color: #100e24;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    background-image: url('https://astroneer.space/assets/img/testimony-bg.jpg');
}

#app-mount {
    display: flex;
    justify-content: space-around;
    height: 100vh;
}

.image_cat {
    margin-top: 10px;
    width: 100%; 
    height: 200px; 
    border-radius: 10px;
}

main {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    flex-direction: row;
    align-items: flex-start;
    height: 100vh;
    margin-top: 50px;
    width: 100vw;
}

.resources {
    text-decoration: none;
    width: max-content;
    padding: 4px 8px;
    margin: 10px 0px;
    border-radius: 20px;
    border: none;
    color: #ffffff;
    background-color: #0077ff;
    transform: scale(1.0);
    transition: all 200ms ease-in;
    justify-items: center;
    &:hover {
        background-color: #0044ff;
        cursor: pointer;
    }
}

.sidebars {
    background-color: var(--primary-color);
    backdrop-filter: blur(2px);
    width: 300px; 
    height: auto; 
    border-radius: 20px;
    position: static;
    padding: 10px;
    box-shadow: var(--all-dark-shadow);
    border: 2px solid var(--border-color);
    z-index: 100;
}

.center_sidebar {
    background-color: var(--primary-color);
    backdrop-filter: blur(2px);
    width: 440px; 
    min-height: 200px;
    max-height: auto; 
    border-radius: 20px;
    position: static;
    padding: 10px;
    box-shadow: var(--all-dark-shadow);
    border: 2px solid var(--border-color);
    z-index: 100;    
    margin: 0px 50px;
}

.loading {
    width: 100%;
    height: 184px;
    background-image: url('https://i.pinimg.com/736x/19/3d/69/193d69a53f12b72eef63a63d248adefc.jpg');
}

h2 {
    color: var(--player-color);
    font-size: 32px;
    margin: 10px 0px;
}

h3 {
    color: var(--player-color);
    font-size: 24px;
    margin: 10px 0px;
}

p {
    color: #dedede;
    margin: 6px 2px;
    font-size: 20px;
}

p a {
    text-decoration: none;
    color: white;
}

.player_count {
    color: var(--player-color);
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 32px;
    font-family: 'Inter';
}
.player_text {
    color: var(--player-color);
    font-size: 16px;
    font-family: 'Inter';
}

.buttons-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-content: center;
}

.button {
    border-radius: 5px;
    border: none;
    width: 100%;
    padding: 6px;
    margin: 6px;
    font-size: 20px;
    font-family: 'Inter';
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    transform: scale(1.0);
    background-color: #202020;
    transition: all 300ms ease-in;
    color: #cccccc;

    &:hover {
        transform: scale(1.05);
        color: #f4d8f8;
        border-radius: 15px;
    }
}

.button svg {
    vertical-align: middle;
    justify-content: baseline;
    text-align: center;
    margin-right: 10px;
    width: 20px;
    aspect-ratio: 1;
}

/* Individual button colors */
#discord:hover, #discord:active {
    background-color: #5662f6;
}

#telegram:hover, #telegram:active {
    background-color: #1188c3;
}

#boosty:hover, #boosty:active {
    background-color: #f15f2c;
}

#youtube:hover, #youtube:active {
    background-color: #ff2424;
}

#twitch:hover, #twitch:active {
    background-color: #6c37ce;
}

#share {
    padding: 8px;
    color: var(--border-color);
    font-size: 16px;
    font-family: 'Inter';
    width: 140px;
    height: 36px;
    background-color: #00f3ff;
    border: none;
    cursor: pointer;
    user-select: none;
    border-radius: 10px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

#share:hover {
    color: var(--primary-color);
    background-color: #00c5de;
}

.avatar_img {
    border-radius: 20%;
    width: 270px;
    height: auto; 
    user-select: none;
    transform: scale3d(1, 1, 1);
    transition: all 200ms ease-in;
    z-index: 101;
    &:hover {
      transform: scale(1.2);
    }
}



::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.line {
    border-radius: 2px; 
    border-top: 4px solid var(--border-color); 
    width: 100%;
}

.member {
    position: relative;
    display: inline-block;
    margin: 10px;
}

.poster-cont {
    padding: 0px 0px 10px 0px;
    margin: 20px 0px 0px 0px;
    border-radius: 10px;
    border: 2px solid #e2e2e23b;
    background-color: #7575753b;
    box-shadow: 0px 0px 20px 20px black insert;
    cursor: default;
}

.poster-cont .poster-img {
    width: 100%;
    margin: 0px;
    border-radius: 10px 10px 0px 0px;

}

.poster-cont .poster-capt {
    margin: 10px;
    font-family: "Inter";
    font-style: normal;
}

.disclaimer {
    color: red;
    font-size: large;
    text-align: center;
    animation: glow 2s infinite alternate;
}

@keyframes glow {
    0% {
        text-shadow: 0 0 3px rgba(255, 0, 0, 0.5), 0 0 5px rgba(255, 0, 0, 0.2);
    }
    50% {
        text-shadow: 0 0 3px rgba(255, 0, 0, 0.8), 0 0 7px rgba(255, 0, 0, 0.7);
    }
    100% {
        text-shadow: 0 0 3px rgba(255, 0, 0, 0.5), 0 0 5px rgba(255, 0, 0, 0.2);
    }
}


