/* Центрируем контент по экрану */
@import url(../GoogleFonts.css);

.center {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #f7f7f7;
    text-align: center;
    margin: 0;
    background-color: rgb(16, 16, 16);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.goBackButton {
    margin-top: 20px; 
    width: 150px;
    height: 40px;
    border: none;
    border-radius: 20px;
    background-color: rgb(138, 180, 248);
    font-family: 'Inter', sans-serif;
    font-size: larger;
    cursor: pointer;
    transition: transform 0.4s ease-out, background-color 0.3s ease-out, box-shadow 0.3s ease-out;
    box-shadow: 0 0 5px rgba(138, 180, 248, 0.5);

    &:hover {
        transform: scale(1.08);
        border: none;
        background-color: rgb(100, 180, 200);
        box-shadow: 0 0 10px rgba(100, 180, 200, 0.8);
    }
}


@media (max-width: 600px) {
    .center {
        font-size: 14px;
    }
s
    .goBackButton {
        width: 120px;
        height: 35px; 
        margin-top: 15px; 
        font-size: 16px; 
    }
}
